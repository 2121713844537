import API from '@axios'
import { stringify as toQuery } from 'query-string'

export default {
  confirmSigningOrder(signingRequest) {
    return API.put('/api/signing/confirm', signingRequest)
  },
  createSigningOrder(signingRequest) {
    return API.put('/api/signing/create', signingRequest)
  },
  getOpenOrder(documentId) {
    return API.get(`/api/signing/get-open/${documentId}`)
  },
    getOrderList(documentId) {
      return API.get(`/api/signing/get-orders/${documentId}`)
    },
    completeTask(taskId) {
      return API.get(`https://dev-api.prozess.com/rest/auth/callback/signing/completed/${taskId}`)
    },
    getDocumentArchive(taskStatusResponse) {
      return API.put('/api/signicat-signing/document/get-result', taskStatusResponse)
    },

}
