import API from '@axios'
import { stringify as toQuery } from 'query-string'

const API_URL = '/api/documents/signature'

export default {
  uploadSessionDocument(documentSingleResponse) {
    const uploadSessionDocumentRequest = {
      documentUuid: documentSingleResponse.uuid,
      versionId: documentSingleResponse.versions[0].id,
    }
    return API.put('/api/signicat-signing/document/upload', uploadSessionDocumentRequest)
  },
  getDocument(documentId) {
    return API.get(`/api/signicat-signing/document/get/${documentId}`)
  },
  deleteDocument(documentId) {
    return API.delete(`/api/signicat-signing/document/delete/${documentId}`)
  },
  async requestSign(signingRequest) {
    return API.put('/api/signing/create', signingRequest)
  },
  getOrder(orderId) {
    return API.get(`/api/signicat-signing/order/get/${orderId}`)
  },
  getAuthenticationArtifact(orderId, taskId) {
    return API.get(`/api/signicat-signing/auth/get/${orderId}/${taskId}`)
  },
  getSignedDocument(orderId, taskId, documentId) {
    return API.get(`/api/signicat-signing/document/getSigned/${orderId}/${taskId}/${documentId}`)
  },
  getTaskStatus(orderId, taskId) {
    return API.get(`/api/signicat-signing/task/getStatus/${orderId}/${taskId}`)
  },
  getTaskEvents(orderId, taskId) {
    return API.get(`/api/signicat-signing/task/getEvents/${orderId}/${taskId}`)
  },

}
